<template>
  <div class="ae-main">
    <v-col v-if="nonet">
      <v-row>
        <v-col cols="12" class="d-flex justify-center text-center pt-10">
          <v-img
            lazy-src="../assets/nonet.png"
            width="200"
            max-width="250"
            src="../assets/nonet.png"
            cols="12"
          ></v-img>
        </v-col>
        <v-col cols="12" class="justify-center text-center">
          <h4 cols="12">Connect to the internet</h4>
          <p>You're offline. Check your connection</p>
          <v-btn text color="primary" @click="$router.go()"> Retry </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-data-iterator
        :items="employees"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
      >
        <template v-slot:header>
          <v-card class="mb-4" :elevation="0">
            <v-card-title class="primary--text">
              <h3>All Employees</h3>
              <v-spacer class="d-block d-md-none"></v-spacer>
              <v-btn
                v-if="user.role == 1 || user.role == 5"
                class="ms-3"
                link
                to="/employees/all/admin"
                color="primary--text"
              >
                <v-icon> mdi-account-details </v-icon>
                <span class="d-none d-md-block"> Admin View </span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
            </v-card-title>
          </v-card>
        </template>
        <template v-slot:default="props">
          <v-row dense>
            <v-col
              v-for="(item, i) in props.items"
              :key="i"
              cols="12"
              lg="3"
              md="4"
              sm="6"
            >
              <v-card class="mx-auto" max-width="344" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ item.designation }}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ item.first_name }} {{ item.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >{{ item.primary_email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="80"
                    :color="
                      '#' + ((Math.random() * 0xffffff) << 0).toString(16)
                    "
                  >
                    <v-img v-if="item.profile" :src="item.profile"></v-img>
                    <span v-else class="white--text text-h3"
                      >{{ item.first_name.charAt(0)
                      }}{{ item.last_name.charAt(0) }}</span
                    >
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn outlined rounded text @click="more(item.id)">
                    More Info
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template></v-data-iterator
      >
      <v-dialog v-model="detail_view" scrollable max-width="400px">
        <v-card v-if="employee_detail">
          <v-card-text style="height: 100%" class="pt-5">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td colspan="2" class="text-center pb-5">
                      <v-list-item-avatar
                        size="150"
                        :color="
                          '#' + ((Math.random() * 0xffffff) << 0).toString(16)
                        "
                      >
                        <v-img
                          v-if="employee_detail.profile"
                          :src="employee_detail.profile"
                        ></v-img>
                        <span v-else class="white--text text-h1"
                          >{{ employee_detail.first_name.charAt(0)
                          }}{{ employee_detail.last_name.charAt(0) }}</span
                        >
                      </v-list-item-avatar>
                      <h1>
                        {{ employee_detail.first_name }}
                        {{ employee_detail.last_name }}
                      </h1>
                      <h2 class="font-weight-light">
                        {{ employee_detail.designation }}
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td><v-icon color="indigo"> mdi-phone </v-icon></td>
                    <td class="py-3">
                      <h4 class="font-weight-medium">
                        {{ employee_detail.primary_mobile_no }}
                      </h4>
                      <h5 class="grey--text lighten-5 font-weight-light">
                        Primary Mobile
                      </h5>
                      <h4
                        v-if="employee_detail.secondary_mobile_no"
                        class="mt-3 font-weight-medium"
                      >
                        {{ employee_detail.secondary_mobile_no }}
                      </h4>
                      <h5
                        v-if="employee_detail.secondary_mobile_no"
                        class="grey--text lighten-5 font-weight-light"
                      >
                        Secondary Mobile
                      </h5>
                      <h4
                        v-if="employee_detail.fixed_no"
                        class="mt-3 font-weight-medium"
                      >
                        {{ employee_detail.fixed_no }}
                      </h4>
                      <h5
                        v-if="employee_detail.fixed_no"
                        class="grey--text lighten-5 font-weight-light"
                      >
                        Fixed
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td><v-icon color="indigo"> mdi-email </v-icon></td>
                    <td class="py-3">
                      <h4 class="font-weight-medium">
                        {{ employee_detail.primary_email }}
                      </h4>
                      <h5 class="grey--text lighten-5 font-weight-light">
                        Primary E-mail
                      </h5>
                      <h4
                        class="mt-3 font-weight-medium"
                        v-if="employee_detail.secondary_email"
                      >
                        {{ employee_detail.secondary_email }}
                      </h4>
                      <h5
                        v-if="employee_detail.secondary_email"
                        class="grey--text lighten-5 font-weight-light"
                      >
                        Secondary E-mail
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td><v-icon color="indigo"> mdi-map-marker </v-icon></td>
                    <td class="py-3">
                      <h4 class="font-weight-medium">
                        {{ employee_detail.address_1 }}
                        {{ employee_detail.address_2 }},
                      </h4>
                      <h4 class="grey--text lighten-5 font-weight-light">
                        {{ employee_detail.city }}, {{ employee_detail.state }},
                        {{ employee_detail.country }},
                        {{ employee_detail.postal_code }}
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <td><v-icon color="indigo"> mdi-water </v-icon></td>
                    <td class="py-3">
                      <h4 class="font-weight-medium">
                        {{ employee_detail.blood_group }}
                      </h4>
                      <h4 class="grey--text lighten-5 font-weight-light">
                        Blood Group
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon color="indigo"> mdi-calendar-range </v-icon>
                    </td>
                    <td class="py-3">
                      <h4 class="font-weight-medium">
                        {{ employee_detail.date_of_birth }}
                      </h4>
                      <h4 class="grey--text lighten-5 font-weight-light">
                        Birthday
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="grey lighten-5">
            <v-btn color="blue darken-1" text @click="detail_view = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "Dashboard",
  data: () => ({
    nonet: false,
    itemsPerPage: 5,
    itemsPerPageArray: [4, 8, 12],
    search: "",
    filter: {},
    sortDesc: false,
    page: 1,
    employees: [],
    snackbar: false,
    text: ``,
    timeout: 3000,
    employee_detail: null,
    detail_view: false,
    user: [],
  }),
  methods: {
    more(id) {
      axios
        .get(this.$backend_url+"employee-detail", {
          params: {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
            emp_id: id,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.employee_detail = response.data.employee_detail;
            this.detail_view = true;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while receiving data.`;
        });
      this.detail_log = true;
    },
  },

  mounted() {
    axios
      .get(this.$backend_url+"employees", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          user: JSON.parse(localStorage.imso_user)["email"],
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.employees = response.data.employees;
        }
        this.nonet = false;
      })
      .catch((response) => {
        console.log("Errors:", response);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });

    this.user = JSON.parse(localStorage.imso_user);
  },
};
</script>

<style>
.ae-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 50px);
  min-height: calc(100vh - 200px);
}

@media only screen and (max-width: 600px) {
  .ae-main {
    margin-left: 0px;
    width: 100vw;
  }
}
</style>